"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sharepointLogo = exports.polSharepointLogo = exports.slackIcon = exports.polSlackIcon = exports.placeholderImage = exports.polPlaceholderImage = exports.tileLogo = exports.polTileLogo = void 0;
exports.polTileLogo = 'https://tile.partnersonline.com/images/tile-logo.png';
exports.tileLogo = 'https://tile.prod.target.com/images/tile-logo.png';
exports.polPlaceholderImage = 'https://tile.partnersonline.com/images/placeholder-image.png';
exports.placeholderImage = 'https://tile.prod.target.com/images/placeholder-image.png';
exports.polSlackIcon = 'https://tile.partnersonline.com/images/slack-icon.png';
exports.slackIcon = 'https://tile.prod.target.com/images/slack-icon.png';
exports.polSharepointLogo = 'https://tile.partnersonline.com/images/sharepoint-logo.png';
exports.sharepointLogo = 'https://tile.prod.target.com/images/sharepoint-logo.png';
