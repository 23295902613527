"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityLogEnum = exports.FolderActivityLogEnum = exports.AssortmentActivityLogEnum = exports.TileActivityLogEnum = void 0;
var TileActivityLogEnum;
(function (TileActivityLogEnum) {
    TileActivityLogEnum["TileCreateComment"] = "TILE_CREATE_COMMENT";
    TileActivityLogEnum["TileDeleteComment"] = "TILE_DELETE_COMMENT";
    TileActivityLogEnum["TileRestoreComment"] = "TILE_RESTORE_COMMENT";
    TileActivityLogEnum["TileUpdateComment"] = "TILE_UPDATE_COMMENT";
    TileActivityLogEnum["TileUpdateDescription"] = "TILE_UPDATE_DESCRIPTION";
    TileActivityLogEnum["AddImage"] = "ADD_IMAGE";
    TileActivityLogEnum["DeleteImage"] = "DELETE_IMAGE";
    TileActivityLogEnum["RestoreImage"] = "RESTORE_IMAGE";
    TileActivityLogEnum["DeleteSourceImage"] = "DELETE_SOURCE_IMAGE";
    TileActivityLogEnum["MakePrimaryThumbnail"] = "MAKE_PRIMARY_THUMBNAIL";
    TileActivityLogEnum["CreateTag"] = "CREATE_TAG";
    TileActivityLogEnum["UpdateTag"] = "UPDATE_TAG";
    TileActivityLogEnum["DeleteTag"] = "DELETE_TAG";
    TileActivityLogEnum["RestoreTag"] = "RESTORE_TAG";
    TileActivityLogEnum["UpdateSize"] = "UPDATE_SIZE";
    TileActivityLogEnum["TileRevertedLaunch"] = "TILE_HAS_REVERTED_LAUNCH";
    TileActivityLogEnum["TileHasLaunched"] = "TILE_HAS_LAUNCHED";
    TileActivityLogEnum["TileSparkDropped"] = "TILE_SPARK_DROPPED";
    TileActivityLogEnum["TileUpdateNote"] = "TILE_UPDATE_NOTE";
    TileActivityLogEnum["TileUpdateNoteBackgroundColor"] = "TILE_UPDATE_NOTE_BACKGROUND_COLOR";
    TileActivityLogEnum["TileAddInclusiveDesignAttribute"] = "TILE_ADD_DESIGN_ATTRIBUTES";
    TileActivityLogEnum["TileDeleteInclusiveDesignAttribute"] = "TILE_DELETE_DESIGN_ATTRIBUTES";
    TileActivityLogEnum["TileAddTileLink"] = "TILE_ADD_TILE_LINK";
    TileActivityLogEnum["TileDeleteTileLink"] = "TILE_DELETE_TILE_LINK";
})(TileActivityLogEnum || (exports.TileActivityLogEnum = TileActivityLogEnum = {}));
var AssortmentActivityLogEnum;
(function (AssortmentActivityLogEnum) {
    AssortmentActivityLogEnum["AssortmentCreateComment"] = "ASSORTMENT_CREATE_COMMENT";
    AssortmentActivityLogEnum["AssortmentDeleteComment"] = "ASSORTMENT_DELETE_COMMENT";
    AssortmentActivityLogEnum["AssortmentRestoreComment"] = "ASSORTMENT_RESTORE_COMMENT";
    AssortmentActivityLogEnum["AssortmentUpdateComment"] = "ASSORTMENT_UPDATE_COMMENT";
    AssortmentActivityLogEnum["AssortmentUpdateDescription"] = "ASSORTMENT_UPDATE_DESCRIPTION";
    AssortmentActivityLogEnum["AssortmentAddWriteUsers"] = "ASSORTMENT_ADD_WRITE_USERS";
    AssortmentActivityLogEnum["AssortmentDeleteWriteUsers"] = "ASSORTMENT_DELETE_WRITE_USERS";
    AssortmentActivityLogEnum["AssortmentAddReadUsers"] = "ASSORTMENT_ADD_READ_USERS";
    AssortmentActivityLogEnum["AssortmentDeleteReadUsers"] = "ASSORTMENT_DELETE_READ_USERS";
    AssortmentActivityLogEnum["AssortmentUploadCoverImage"] = "ASSORTMENT_UPLOAD_COVER_IMAGE";
    AssortmentActivityLogEnum["AssortmentRemoveCoverImage"] = "ASSORTMENT_REMOVE_COVER_IMAGE";
    AssortmentActivityLogEnum["AddTiles"] = "ADD_TILES";
    AssortmentActivityLogEnum["CopyTiles"] = "COPY_TILES";
    AssortmentActivityLogEnum["CreateTile"] = "CREATE_TILE";
    AssortmentActivityLogEnum["MoveTiles"] = "MOVE_TILES";
    AssortmentActivityLogEnum["DeleteTiles"] = "DELETE_TILES";
    AssortmentActivityLogEnum["RenameAssortment"] = "RENAME_ASSORTMENT";
    AssortmentActivityLogEnum["CreateSection"] = "CREATE_SECTION";
    AssortmentActivityLogEnum["RenameSection"] = "RENAME_SECTION";
    AssortmentActivityLogEnum["DeleteSection"] = "DELETE_SECTION";
    AssortmentActivityLogEnum["RestoreSection"] = "RESTORE_SECTION";
    AssortmentActivityLogEnum["CreateBlueprint"] = "CREATE_BLUEPRINT";
    AssortmentActivityLogEnum["RenameBlueprint"] = "RENAME_BLUEPRINT";
    AssortmentActivityLogEnum["DeleteBlueprint"] = "DELETE_BLUEPRINT";
    AssortmentActivityLogEnum["RestoreBlueprint"] = "RESTORE_BLUEPRINT";
})(AssortmentActivityLogEnum || (exports.AssortmentActivityLogEnum = AssortmentActivityLogEnum = {}));
var FolderActivityLogEnum;
(function (FolderActivityLogEnum) {
    FolderActivityLogEnum["CreateAssortment"] = "CREATE_ASSORTMENT";
    FolderActivityLogEnum["CreateFolder"] = "CREATE_FOLDER";
    FolderActivityLogEnum["DeleteAssortment"] = "DELETE_ASSORTMENT";
    FolderActivityLogEnum["DeleteFolder"] = "DELETE_FOLDER";
    FolderActivityLogEnum["RestoreAssortment"] = "RESTORE_ASSORTMENT";
    FolderActivityLogEnum["RestoreFolder"] = "RESTORE_FOLDER";
    FolderActivityLogEnum["FolderAddWriteUsers"] = "FOLDER_ADD_WRITE_USERS";
    FolderActivityLogEnum["FolderDeleteWriteUsers"] = "FOLDER_DELETE_WRITE_USERS";
    FolderActivityLogEnum["FolderAddReadUsers"] = "FOLDER_ADD_READ_USERS";
    FolderActivityLogEnum["FolderDeleteReadUsers"] = "FOLDER_DELETE_READ_USERS";
    FolderActivityLogEnum["FolderUploadCoverImage"] = "FOLDER_UPLOAD_COVER_IMAGE";
    FolderActivityLogEnum["FolderRemoveCoverImage"] = "FOLDER_REMOVE_COVER_IMAGE";
    FolderActivityLogEnum["RenameFolder"] = "RENAME_FOLDER";
})(FolderActivityLogEnum || (exports.FolderActivityLogEnum = FolderActivityLogEnum = {}));
exports.ActivityLogEnum = __assign(__assign(__assign({}, TileActivityLogEnum), AssortmentActivityLogEnum), FolderActivityLogEnum);
